// src/pages/Details.js
import { useAuth } from "../context/AuthProvider";
import FileNamesComponent from "../components/FileNamesComponent";
import NavigationBar from "../components/Navigation";

const Details = () => {
  const { user } = useAuth();

  return (
    <div>
      <NavigationBar>
        {user && <FileNamesComponent user={user} />}
      </NavigationBar>
    </div>
  );
};

export default Details;
