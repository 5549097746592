import React, { useState } from "react";
import axios from "axios";
import "./fileName.css";
import toast from "react-hot-toast";
import BotFrequencyDropdown from "./BotFrequencyDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import BotTypeDropdown from "./BotTypeDropdown";
import LastSearchDropDown from "./LastSearchDropDown";

const FileNamesComponent = ({ user }) => {
  const location = useLocation();
  let bot = {};
  let action = "";
  if (location.state) {
    bot = location.state.bot;
    action = location.state.action;
  }

  const navigate = useNavigate();

  const initialState = {
    _id: action === "update" ? bot._id : "",
    name: action === "update" ? bot?.name : "",
    agencyFileName:
      action === "update" ? bot?.agenciesFileName?.slice(9, -5) : "",
    keyWordsFileName:
      action === "update" ? bot?.keywordsFileName?.slice(9, -5) : "",
    captureFileName:
      action === "update"
        ? bot.bot_type?.toLowerCase() === "awardees"
          ? bot?.awardeeContractsFileName?.slice(17, -5)
          : bot.bot_type?.toLowerCase() === "contracts"
          ? bot?.vehicleCollectionsFileName?.slice(14, -5)
          : bot?.captureFileName?.slice(8, -5)
        : "",

    botFrequency:
      action === "update"
        ? bot?.botFrequency === "Once"
          ? "One-Time"
          : bot?.botFrequency
        : "One-Time",
    botType: action === "update" ? bot?.bot_type : "Opportunities",
    scheduledTime: action === "update" ? bot?.scheduledTime?.split(" ")[0] : "",
    maxNumberOfResults: action === "update" ? bot?.maxNumberOfResults : "",
    isActive: action === "update" ? !bot?.isActive : false,
    withinNext12Months:
      action === "update" ? bot?.withinNextTwelveMonths : true,
    lastDateToOrder: action === "update" ? bot?.lastDateToOrder : false,
    quickSearch: action === "update" ? bot?.quickSearch : false,
    lastSearchRecords:
      action === "update"
        ? bot?.lastSearchRecords
          ? bot?.lastSearchRecords
          : "None"
        : "None",
  };

  const [name, setName] = useState(initialState.name);
  const [agencyFileName, setAgencyFileName] = useState(
    initialState.agencyFileName
  );
  const [keyWordsFileName, setKeywordsFileName] = useState(
    initialState.keyWordsFileName
  );
  const [captureFileName, setCaptureFileName] = useState(
    initialState?.captureFileName
  );

  const [botFrequency, setBotFrequency] = useState(initialState.botFrequency);
  const [botType, setBotType] = useState(initialState.botType);
  const [scheduledTime, setScheduledTime] = useState(
    initialState.scheduledTime
  );
  const [maxNumberOfResults, setMaxNumberOfResults] = useState(
    initialState.maxNumberOfResults
  );
  const [isActive, setIsActive] = useState(initialState.isActive);
  const [withinNext12Months, setWithinNext12Months] = useState(
    initialState.withinNext12Months
  );
  const [lastDateToOrder, setLastDateToOrder] = useState(
    initialState.lastDateToOrder
  );
  const [quickSearch, setQuickSearch] = useState(initialState.quickSearch);
  const [lastSearchRecords, setLastSearchRecords] = useState(
    initialState.lastSearchRecords
  );

  const onScheduledTimeChange = (e) => {
    console.log(e.target.value);
    setScheduledTime(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!scheduledTime) {
        toast.error("Scheduled time is mandatory");
        return;
      }
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const requestData = {
        userId: user.user._id,
        _id: initialState._id,
        name: name,
        agenciesFileName: `Agencies_${agencyFileName}.xlsx`,
        keywordsFileName: `Keywords_${keyWordsFileName}.xlsx`,
        botFrequency:
          botFrequency.toLowerCase() === "one-time" ? "Once" : botFrequency,
        scheduledTime: scheduledTime + " " + timeZone.toString(),
        maxNumberOfResults: parseInt(maxNumberOfResults),
        isActive: isActive,
        withinNextTwelveMonths: withinNext12Months,
        bot_type: botType?.toLowerCase(),
        quickSearch: quickSearch,
        lastDateToOrder: lastDateToOrder,
        lastSearchRecords:
          lastSearchRecords === "None"
            ? null
            : lastSearchRecords?.toLowerCase(),
      };

      // if (lastSearchRecords === "Last Week")
      //   requestData.lastSearchRecords = "weekly";
      // if (lastSearchRecords === "Last Day")
      //   requestData.lastSearchRecords = "daily";
      // if (lastSearchRecords === "Last Month")
      //   requestData.lastSearchRecords = "monthly";
      // if (lastSearchRecords === "Last Year")
      //   requestData.lastSearchRecords = "yearly";
      // if (lastSearchRecords === "None") requestData.lastSearchRecords = "None";

      // Conditionally add keys based on botType
      if (botType?.toLowerCase() === "awardees") {
        requestData.awardeeContractsFileName = `AwardeeContracts_${captureFileName}.xlsx`;
        requestData.awardeeCollectionsFileName = `AwardeeCollections_${captureFileName}.xlsx`;
      } else if (botType?.toLowerCase() === "opportunities") {
        requestData.captureFileName = `Capture_${captureFileName}.xlsx`;
        requestData.collectionsFileName = `Collections_${captureFileName}.xlsx`;
      } else if (botType?.toLowerCase() === "contracts") {
        requestData.vehicleContractsFileName = `Contracts_${captureFileName}.xlsx`;
        requestData.vehicleCollectionsFileName = `ContractScope_${captureFileName}.xlsx`;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/file/submit`,
        requestData
      );

      if (response.statusText === "OK") {
        console.log(response.data._id);
        toast.success("Submitted Successfully");
        navigate("/bot-details");
        await axios.post(`${process.env.REACT_APP_BOT_URL}/scrape_govtribe`, {
          _id: response.data._id,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err);
      }
    }
  };

  const toggleIsActive = () => {
    setIsActive((prev) => !prev);
  };

  const handleNewEntry = async (e) => {
    try {
      e.preventDefault();
      if (!scheduledTime) {
        toast.error("Scheduled time is mandatory");
        return;
      }
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const requestData = {
        userId: user.user._id,
        _id: initialState._id,
        name: name,
        agenciesFileName: `Agencies_${agencyFileName}.xlsx`,
        keywordsFileName: `Keywords_${keyWordsFileName}.xlsx`,
        botFrequency:
          botFrequency.toLowerCase() === "one-time" ? "Once" : botFrequency,
        scheduledTime: scheduledTime + " " + timeZone.toString(),
        maxNumberOfResults: parseInt(maxNumberOfResults),
        isActive: isActive,
        withinNextTwelveMonths: withinNext12Months,
        bot_type: botType.toLowerCase(),
        quickSearch: quickSearch,
        lastDateToOrder: lastDateToOrder,
        lastSearchRecords:
          lastSearchRecords === "None"
            ? null
            : lastSearchRecords?.toLowerCase(),
      };

      // Conditionally add keys based on botType
      if (botType?.toLowerCase() === "awardees") {
        requestData.awardeeContractsFileName = `AwardeeContracts_${captureFileName}.xlsx`;
        requestData.awardeeCollectionsFileName = `AwardeeCollections_${captureFileName}.xlsx`;
      } else if (botType?.toLowerCase() === "opportunities") {
        requestData.captureFileName = `Capture_${captureFileName}.xlsx`;
        requestData.collectionsFileName = `Collections_${captureFileName}.xlsx`;
      } else if (botType?.toLowerCase() === "contracts") {
        requestData.vehicleContractsFileName = `Contracts_${captureFileName}.xlsx`;
        requestData.vehicleCollectionsFileName = `ContractScope_${captureFileName}.xlsx`;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/file/createNewEntry`,
        requestData
      );
      if (response.status === 201) {
        console.log(response.data._id);

        toast.success("Bot Created Successfully");
        navigate("/bot-details");
        await axios.post(`${process.env.REACT_APP_BOT_URL}/scrape_govtribe`, {
          _id: response.data._id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="toggle-h1-container">
          {/* <h1>Submit File Identifiers</h1> */}
          <div className="input-group-name">
            <input
              type="text"
              placeholder="Enter bot Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isActive}
                onChange={toggleIsActive}
              />
              <span className="slider round"></span>
            </label>
            <span>{"Pause Bot"}</span>
          </div>
        </div>

        <div className="input-group">
          <div className="prefix">Agencies_</div>
          <input
            type="text"
            value={agencyFileName}
            onChange={(e) => setAgencyFileName(e.target.value)}
          />
          <div className="suffix">.xlsx</div>
        </div>
        <div className="input-group">
          <div className="prefix">Keywords_</div>
          <input
            type="text"
            value={keyWordsFileName}
            onChange={(e) => setKeywordsFileName(e.target.value)}
          />
          <div className="suffix">.xlsx</div>
        </div>
        <div className="input-group">
          <div className="prefix">Output_</div>
          <input
            type="text"
            value={captureFileName}
            onChange={(e) => setCaptureFileName(e.target.value)}
          />
          <div className="suffix">.xlsx</div>
        </div>
        <div className="dropdown-div">
          <BotFrequencyDropdown
            botFrequency={botFrequency}
            setBotFrequency={setBotFrequency}
          />
          <BotTypeDropdown botType={botType} setBotType={setBotType} />
          <LastSearchDropDown
            lastSearchRecords={lastSearchRecords}
            setLastSearchRecords={setLastSearchRecords}
          />
        </div>
        <div className="checkbox-container">
          {botType?.toLowerCase() === "contracts" ? (
            <div>
              <input
                type="checkbox"
                checked={lastDateToOrder}
                onChange={(e) => {
                  setLastDateToOrder(e.target.checked);
                }}
              />
              <label>Last Date To Order</label>
            </div>
          ) : (
            <div>
              <input
                type="checkbox"
                checked={withinNext12Months}
                onChange={(e) => {
                  setWithinNext12Months(e.target.checked);
                }}
              />
              <label>Within next 12 months</label>
            </div>
          )}

          <div>
            <input
              type="checkbox"
              checked={quickSearch}
              onChange={(e) => {
                setQuickSearch(e.target.checked);
              }}
            />
            <label>Quick Search</label>
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-group-maxquestions">
            <p className="question">Maximum Number Of Results</p>
            <input
              type="text"
              value={maxNumberOfResults}
              onChange={(e) => setMaxNumberOfResults(e.target.value)}
            />
          </div>
          <div className="input-group-time">
            <p className="question">Preferred time to run the bot</p>
            <input
              type="time"
              value={scheduledTime}
              onChange={onScheduledTimeChange}
            />
          </div>
        </div>

        {action === "update" ? (
          <button onClick={handleSubmit}>Update Details</button>
        ) : (
          <button onClick={handleNewEntry}>Create Another Bot</button>
        )}
      </div>
    </div>
  );
};

export default FileNamesComponent;
