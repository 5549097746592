
import axios from "axios";
import { Table, Button } from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"; // Updated import
import { useState, useEffect } from "react";
import { createStyles } from "@mantine/styles";

// Custom styles using Mantine's `createStyles`
const useStyles = createStyles((theme) => ({
  tableContainer: {
    width: "50%", // Ensure the container takes full width
    height: "220px", // Limit the height of the table container
    overflowY: "auto", // Vertical scroll only
    marginLeft: "10px",
    marginRight: "auto",
    padding: "20px", // Add padding for spacing
    backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C", // Container background
    borderRadius: theme.radius.md, // Rounded corners
    boxShadow: theme.shadows.md, // Box shadow for better visual hierarchy

    /* Custom Scrollbar */
    "::-webkit-scrollbar": {
      width: "10px",
    },
    "::-webkit-scrollbar-track": {
      background: "#1A202C",
      borderRadius: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#007bff",
      borderRadius: "5px",
      border: "2px solid #1A202C",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#0056b3",
    },
  },
  table: {
    width: "100%", // Make sure the table takes full width of the container
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C",
  },
  thead: {
    backgroundColor: theme.colors.blue[6],
    color: theme.white,
  },
  th: {
    padding: "10px 20px",
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },
  tr: {
    backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : "#1A202C",
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  td: {
    padding: "12px 20px",
    fontSize: theme.fontSizes.sm,
    color: "white",
  },
  button: {
    fontSize: theme.fontSizes.xs,
    padding: "8px 16px",
    borderRadius: theme.radius.sm,
    backgroundColor: theme.colors.blue[6],
    "&:hover": {
      backgroundColor: theme.colors.blue[7],
    },
    cursor: "pointer",
  },
  activateButton: {
    backgroundColor: theme.colors.green[6],
    "&:hover": {
      backgroundColor: theme.colors.green[7],
    },
  },
  deactivateButton: {
    backgroundColor: theme.colors.red[6],
    "&:hover": {
      backgroundColor: theme.colors.red[7],
    },
  },
}));

const fetchUsers = async () => {
  const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/auth/getAllUsers`,
      {
        withCredentials: true,
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
  );
  console.log("users from fetching", response.data);
  if (response.status === 200) return response.data.users;

  return "Error Fetching Users";
};

const TableComponent = () => {
  const queryClient = useQueryClient();
  const { classes, cx } = useStyles();

  // State to track the current processing user
  const [processingUserId, setProcessingUserId] = useState(null);

  // Fetch users with Tanstack React Query
  const {
    data: users,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
    enabled: false,
    select: (data) =>
        data.filter(
            (user) =>
                user.email !== process.env.REACT_APP_ADMIN_EMAIL_FIRST &&
                user.email !== process.env.REACT_APP_ADMIN_EMAIL_SECOND
        ),
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  // Mutation for activating/deactivating users
  const mutation = useMutation({
    mutationFn: ({ userId, isUserActive }) =>
        axios.post(
            `${process.env.REACT_APP_API_URL}/auth/deactivateBots`,
            { userId, isUserActive },
            { withCredentials: true }
        ),
    onMutate: async (variables) => {
      setProcessingUserId(variables.userId);

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["users"] });

      // Snapshot the previous value
      const previousUsers = queryClient.getQueryData(["users"]);

      // Optimistically update the active state locally
      queryClient.setQueryData(["users"], (old) =>
          old.map((user) =>
              user._id === variables.userId
                  ? { ...user, isUserActive: !variables.isUserActive }
                  : user
          )
      );

      // Return the snapshotted value in case of rollback
      return { previousUsers };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (err, variables, context) => {
      // Rollback to previous state in case of an error
      queryClient.setQueryData(["users"], context.previousUsers);
    },
    onSettled: () => {
      // Reset the processing state after mutation is completed
      setProcessingUserId(null);
    },
  });

  const handleActivate = (userId, isUserActive) => {
    mutation.mutate({ userId, isUserActive });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching users: {error.message}</div>;
  }

  const rows = users?.map((user, index) => (
      <Table.Tr
          key={user._id}
          className={classes.tr}
          style={{ position: "sticky" }}
      >
        <Table.Td className={classes.td} data-label="Serial Number">
          {index + 1}
        </Table.Td>
        <Table.Td className={classes.td} data-label="Email">
          {user.email}
        </Table.Td>
        <Table.Td className={classes.td} data-label="Action">
          <Button
              className={cx(
                  classes.button,
                  user.isUserActive
                      ? classes.deactivateButton
                      : classes.activateButton
              )}
              onClick={() => handleActivate(user._id, user.isUserActive)}
              disabled={processingUserId === user._id} // Disable only if this user is processing
          >
            {processingUserId === user._id
                ? "Processing..."
                : user.isUserActive
                    ? "Deactivate"
                    : "Activate"}
          </Button>
        </Table.Td>
      </Table.Tr>
  ));

  return (
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <Table.Thead className={classes.thead}>
            <Table.Tr>
              <Table.Th className={classes.th}>Serial Number</Table.Th>
              <Table.Th className={classes.th}>Email</Table.Th>
              <Table.Th className={classes.th}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
  );
};

export default TableComponent;
