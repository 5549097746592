import React, { useState } from "react";
import styles from "./CreateUser.module.css";
import { toast } from "react-hot-toast";

const UpdateUserModal = ({ onClose }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/updatePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        toast.success("Password Updated Successfully!");
        onClose(); // Close the modal
      } else if (response.status === 404) {
        toast.error("User does not exist");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButtonModal} onClick={onClose}>
          &times;
        </button>
        <h2 style={{ color: "black" }}>Update Existing User</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.inputBox}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.inputBox}
        />
        <button onClick={handleCreateUser} className={styles.createButtonModal}>
          Update Password
        </button>
      </div>
    </div>
  );
};

export default UpdateUserModal;
