// import axios from "axios";
// import { Table, Button } from "@mantine/core";
// import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"; // Updated import
// import { useState } from "react";
// import { createStyles } from "@mantine/styles";
//
// // Custom styles using Mantine's `createStyles`
// const useStyles = createStyles((theme) => ({
//     tableContainer: {
//         width: "95%", // Ensure the container takes full width
//         height: "300px", // Limit the height of the table container
//         overflowY: "auto", // Vertical scroll only
//         marginLeft: "10px",
//         marginRight: "auto",
//         padding: "20px", // Add padding for spacing
//         backgroundColor:
//             theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C", // Container background
//         borderRadius: theme.radius.md, // Rounded corners
//         boxShadow: theme.shadows.md, // Box shadow for better visual hierarchy
//         "::-webkit-scrollbar": {
//             width: "10px",
//         },
//         "::-webkit-scrollbar-track": {
//             background: "#1A202C",
//             borderRadius: "5px",
//         },
//         "::-webkit-scrollbar-thumb": {
//             backgroundColor: "#007bff",
//             borderRadius: "5px",
//             border: "2px solid #1A202C",
//         },
//         "::-webkit-scrollbar-thumb:hover": {
//             backgroundColor: "#0056b3",
//         },
//     },
//
//     table: {
//         width: "100%", // Make sure the table takes full width of the container
//         borderCollapse: "separate",
//         borderSpacing: "0 10px",
//         backgroundColor:
//             theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C",
//     },
//     thead: {
//         backgroundColor: theme.colors.blue[6],
//         color: theme.white,
//     },
//     th: {
//         padding: "10px 20px",
//         fontWeight: 700,
//         fontSize: theme.fontSizes.sm,
//         textAlign: "left",
//         borderBottom: `2px solid ${
//             theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3]
//         }`,
//     },
//     tr: {
//         backgroundColor:
//             theme.colorScheme === "dark" ? theme.colors.dark[6] : "#1A202C",
//         borderBottom: `1px solid ${theme.colors.gray[3]}`,
//     },
//     td: {
//         padding: "12px 20px",
//         fontSize: theme.fontSizes.sm,
//         color: "white",
//     },
//     deactivateButton: {
//         backgroundColor: theme.colors.red[6],
//         "&:hover": {
//             backgroundColor: theme.colors.red[7],
//         },
//         padding:"5px",
//         borderRadius:"5px",
//         cursor:"pointer"
//     },
// }));
//
// const fetchbots = async () => {
//     const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/file/getAllBots`,
//         {
//             withCredentials: true,
//             headers: {
//                 "Cache-Control": "no-cache",
//                 Pragma: "no-cache",
//                 Expires: "0",
//             },
//         }
//     );
//
//     if (response.status === 200) return response.data.bots;
//
//     throw new Error("Error Fetching bots");
// };
//
// const Showbots = () => {
//     const queryClient = useQueryClient();
//     const { classes } = useStyles();
//
//     const handleDeleteBot = async (botId) =>{
//
//     const response = await axios.delete(`${process.env.REACT_APP_API_URL}/file/delete`, {
//             data: { botId },
//         })
//         console.log(response)
//     }
//
//
//     const { data: bots, isLoading, isError, error } = useQuery({
//         queryKey: ["bots"],
//         queryFn: fetchbots,
//     });
//
//     if (isLoading) {
//         return <div>Loading...</div>;
//     }
//
//     if (isError) {
//         return <div>Error fetching bots: {error.message}</div>;
//     }
//
//     const rows = bots?.map((user, index) => (
//         <Table.Tr key={user._id} className={classes.tr}>
//             <Table.Td className={classes.td} data-label="Serial Number">
//                 {index + 1}
//             </Table.Td>
//             <Table.Td className={classes.td} data-label="Name">
//                 {user.name}
//             </Table.Td>
//             <Table.Td className={classes.td} data-label="Email">
//                 {user.email}
//             </Table.Td>
//
//             <Table.Td data-label="Email">
//                 <Button className={classes.deactivateButton} onClick={()=>handleDeleteBot(user._id)}>Delete Bot</Button>
//             </Table.Td>
//         </Table.Tr>
//     ));
//
//     return (
//         <div className={classes.tableContainer}>
//             <Table className={classes.table}>
//                 <Table.Thead className={classes.thead}>
//                     <Table.Tr>
//                         <Table.Th className={classes.th}>Serial Number</Table.Th>
//                         <Table.Th className={classes.th}>Name</Table.Th>
//                         <Table.Th className={classes.th}>Email</Table.Th>
//                         <Table.Th className={classes.th}>Action</Table.Th>
//                     </Table.Tr>
//                 </Table.Thead>
//                 <Table.Tbody>{rows}</Table.Tbody>
//             </Table>
//         </div>
//     );
// };
//
// export default Showbots;

import axios from "axios";
import { Table, Button } from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"; // Updated import
import { useEffect } from "react";
import { createStyles } from "@mantine/styles";
import toast, {Toaster} from "react-hot-toast"

// Custom styles using Mantine's `createStyles`
const useStyles = createStyles((theme) => ({
    tableContainer: {
        width: "95%", // Ensure the container takes full width
        height: "300px", // Limit the height of the table container
        overflowY: "auto", // Vertical scroll only
        marginLeft: "10px",
        marginRight: "auto",
        padding: "20px", // Add padding for spacing
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C", // Container background
        borderRadius: theme.radius.md, // Rounded corners
        boxShadow: theme.shadows.md, // Box shadow for better visual hierarchy
        "::-webkit-scrollbar": {
            width: "10px",
        },
        "::-webkit-scrollbar-track": {
            background: "#1A202C",
            borderRadius: "5px",
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: "#007bff",
            borderRadius: "5px",
            border: "2px solid #1A202C",
        },
        "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#0056b3",
        },
    },

    table: {
        width: "100%", // Make sure the table takes full width of the container
        borderCollapse: "separate",
        borderSpacing: "0 10px",
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "#1A202C",
    },
    thead: {
        backgroundColor: theme.colors.blue[6],
        color: theme.white,
    },
    th: {
        padding: "10px 20px",
        fontWeight: 700,
        fontSize: theme.fontSizes.sm,
        textAlign: "left",
        borderBottom: `2px solid ${
            theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3]
        }`,
    },
    tr: {
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : "#1A202C",
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    td: {
        padding: "12px 20px",
        fontSize: theme.fontSizes.sm,
        color: "white",
    },
    deactivateButton: {
        backgroundColor: theme.colors.red[6],
        "&:hover": {
            backgroundColor: theme.colors.red[7],
        },
        padding: "5px",
        borderRadius: "5px",
        cursor: "pointer",
    },
}));

const fetchbots = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/file/getAllBots`,
        {
            withCredentials: true,
            headers: {
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
                Expires: "0",
            },
        }
    );

    if (response.status === 200) {
        return response.data.bots;
    }

    throw new Error("Error Fetching bots");
};

const Showbots = () => {
    const queryClient = useQueryClient();
    const { classes } = useStyles();

    const { data: bots, isLoading, isError, error, refetch } = useQuery({
        queryKey: ["bots"],
        queryFn: fetchbots,
    });

    const mutation = useMutation({
        mutationFn: async (botId) => {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/file/delete`, {
                data: { botId },
            });
            if (response.status !== 200) {
                throw new Error("Error deleting bot");
            }
        },
        onSuccess: () => {
            toast.success("bot deleted successfully")
            // Refetch bots after successful deletion
            refetch();
        },
    });

    const handleDeleteBot = (botId) => {
        mutation.mutate(botId);
    };

    useEffect(() => {
        // This will fetch bots whenever the component mounts
        refetch();
    }, [refetch]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching bots: {error.message}</div>;
    }

    const rows = bots?.map((user, index) => (
        <Table.Tr key={user._id} className={classes.tr}>
            <Table.Td className={classes.td} data-label="Serial Number">
                {index + 1}
            </Table.Td>
            <Table.Td className={classes.td} data-label="Name">
                {user.name}
            </Table.Td>
            <Table.Td className={classes.td} data-label="Email">
                {user.email}
            </Table.Td>
            <Table.Td data-label="Email">
                <Button className={classes.deactivateButton} onClick={() => handleDeleteBot(user._id)}>
                    Delete Bot
                </Button>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <div className={classes.tableContainer}>
            <Toaster/>
            <Table className={classes.table}>
                <Table.Thead className={classes.thead}>
                    <Table.Tr>
                        <Table.Th className={classes.th}>Serial Number</Table.Th>
                        <Table.Th className={classes.th}>Name</Table.Th>
                        <Table.Th className={classes.th}>Email</Table.Th>
                        <Table.Th className={classes.th}>Action</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
            </Table>
        </div>
    );
};

export default Showbots;
