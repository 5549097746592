// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import styles from "./ServiceStatus.module.css";

// const ServiceStatus = () => {
//   const [services, setServices] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     async function getServices() {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/getServicesStatus`
//       );
//       const data = await response.data;
//       setServices(data.data);
//       setLoading(false);
//     }
//     getServices();
//   }, []);

//   if (loading) {
//     return (
//       <div className={styles.spinnerContainer}>
//         <div className={styles.spinner}></div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       {services.map((service, index) => (
//         <div key={index} className={styles.serviceItem}>
//           <div className={styles.serviceName}>{service.service}</div>
//           <div className={styles.status}>
//             <div
//               className={`${styles.statusDot} ${
//                 service.status === "active" ? styles.active : styles.inactive
//               }`}
//             ></div>
//             {service.status}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ServiceStatus;

import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./ServiceStatus.module.css";

const ServiceStatus = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getServices() {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getServicesStatus`
      );
      const data = await response.data;
      setServices(data.data);
      setLoading(false);
    }
    getServices();
  }, []);

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Service Status Dashboard</div>
      <div className={styles.description}>
        Monitor the current status of our services in real-time.
      </div>
      <div className={styles.serviceList}>
        {services.map((service, index) => (
          <div key={index} className={styles.serviceItem}>
            <div className={styles.serviceName}>{service.service}</div>
            <div className={styles.status}>
              <div
                className={`${styles.statusDot} ${
                  service.status === "active" ? styles.active : styles.inactive
                }`}
              ></div>
              {service.status}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceStatus;
