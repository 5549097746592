import React from "react";
import Select from "react-select";
import "./BotList.css";

const options = [
  { value: "None", label: "None" },
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Year", label: "Year" },
];

const LastSearchDropDown = ({ lastSearchRecords, setLastSearchRecords }) => {
  const handleChange = (selectedOption) => {
    setLastSearchRecords(selectedOption.value);
  };

  return (
    <div className="input-group-dropdown">
      <p className="question">Search w/in Last</p>
      <Select
        className="select-class"
        value={
          typeof lastSearchRecords === "string" && lastSearchRecords
            ? {
                value: lastSearchRecords,
                label:
                  lastSearchRecords.charAt(0).toUpperCase() +
                  lastSearchRecords.slice(1),
              }
            : null
        }
        onChange={handleChange}
        options={options}
        placeholder="Last Search .."
      />
    </div>
  );
};

export default LastSearchDropDown;
