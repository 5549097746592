import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navigation.css";
import { LuLogOut } from "react-icons/lu";
import { useAuth } from "../context/AuthProvider";

const NavigationBar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const handleBackToBotList = () => {
    navigate("/bot-details");
  };

  const handleBackToServices = () => {
    navigate("/services");
  };
  return (
    <div>
      <nav className="nav-bar">
        <div className="group-button">
          {location.pathname !== "/services" && (
            <button className="services" onClick={handleBackToServices}>
              Services
            </button>
          )}
          <button className="logout" onClick={handleLogout}>
            Logout <LuLogOut />
          </button>
        </div>
        {location.pathname !== "/bot-details" && (
          <button className="homeButton" onClick={handleBackToBotList}>
            View Bots
          </button>
        )}
      </nav>
      {children}
    </div>
  );
};

export default NavigationBar;
