import React from "react";
import Select from "react-select";
import "./BotList.css";

const options = [
  { value: "Opportunities", label: "Opportunities" },
  { value: "Awardees", label: "Awardees" },
  { value: "Contracts", label: "Contracts" },
];

const BotTypeDropdown = ({
  botType,
  setBotType,
  // setCaptureFileName,
}) => {
  const handleChange = (selectedOption) => {
    setBotType(selectedOption.value);
  };

  return (
    <div className="input-group-dropdown">
      <p className="question">Search Type</p>
      <Select
        className="select-class"
        value={
          typeof botType === "string" && botType
            ? {
                value: botType,
                label: botType.charAt(0).toUpperCase() + botType.slice(1),
              }
            : null
        }
        onChange={handleChange}
        options={options}
        placeholder="Bot Type"
      />
    </div>
  );
};

export default BotTypeDropdown;
