import React, { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import Background from "../assets/background2.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/bot-details");
    } catch (error) {
      console.error("Error logging in:", error); // Make sure to log the error for debugging
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-image">
          <img src={Background} alt="Login" />
        </div>
        <div className="login-form">
          <h2>Login</h2>
          <p>Enter your credentials to Login into your account</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              placeholder="johndoe@gmail.com"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              required
            />
            <button type="submit">Login</button>
          </form>
          {/* <div className="nav-signup-container">
            <p className="nav-signup-text">
              Don't have an account?{" "}
              <Link className="nav-signup" to="/signup">
                Sign Up
              </Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
