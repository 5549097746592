import React from "react";
import Select from "react-select";
import "./BotList.css";

const options = [
  { value: "one-time", label: "One-Time" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const BotFrequencyDropdown = ({ botFrequency, setBotFrequency }) => {
  const handleChange = (selectedOption) => {
    console.log(selectedOption.value);
    setBotFrequency(selectedOption.value);
  };

  return (
    <div className="input-group-dropdown">
      <p className="question">Bot Run Frequency</p>
      <Select
        className="select-class"
        value={
          typeof botFrequency === "string" && botFrequency
            ? {
                value: botFrequency,
                label:
                  botFrequency.charAt(0).toUpperCase() + botFrequency.slice(1),
              }
            : null
        }
        onChange={handleChange}
        options={options}
        placeholder="Bot Frequency"
      />
    </div>
  );
};

export default BotFrequencyDropdown;
