import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Details from "./pages/Details";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";
import BotDetails from "./pages/BotDetails";
import ServiceStatus from "./pages/ServiceStatus";
import NavigationBar from "./components/Navigation";
import AdminDashboard from "./pages/AdminDashboard";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/details"
          element={
            <ProtectedRoute>
              <Details />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <NavigationBar>
                <AdminDashboard />
              </NavigationBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/bot-details"
          element={
            <ProtectedRoute>
              <BotDetails />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/services"
          element={
            <ProtectedRoute>
              <NavigationBar>
                <ServiceStatus />
              </NavigationBar>
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
