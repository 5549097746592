// src/pages/Details.js
import { useAuth } from "../context/AuthProvider";
import NavigationBar from "../components/Navigation";
import BotList from "../components/BotList";

const BotDetails = () => {
  const { user } = useAuth();

  return (
    <div>
      <NavigationBar>{user && <BotList user={user} />}</NavigationBar>
    </div>
  );
};

export default BotDetails;
