import React, { useState } from "react";
import styles from "./AdminDashboard.module.css";
import { useLocation } from "react-router-dom";
import CreateUserModal from "./CreateUser";
import UpdateUserModal from "./UpdateUser";
import axios from "axios";
import { toast } from "react-hot-toast";
import TableComponent from "../components/TableComponent";
import ShowUsersModal from "./ShowUsers";

const AdminDashboard = () => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);
  const [isNonAdminTableOpen , setIsNonAdminTableOpen] = useState(false);
  const openCreateUserModal = () => setIsCreateUserModalOpen(true);
  const closeCreateUserModal = () => setIsCreateUserModalOpen(false);
  const openUpdateUserModal = () => setIsUpdateUserModalOpen(true);
  const closeUpdateUserModal = () => setIsUpdateUserModalOpen(false);
  const openShowNonAdminUsersModal = ()=>setIsNonAdminTableOpen(true);
  const closeShowNonAdminUsersModal = ()=>setIsNonAdminTableOpen(false);

  const location = useLocation();
  let user = {};
  if (location.state) {
    user = location.state?.user;
  }

  const handleDeleteFiles = async () => {
    try {
      const response = await axios.post(
        "https://govtribe-utility.patriotlabs.us/delete_records"
      );
      if (response.status === 200) {
        toast.success("Records Deleted Successfully!");
      } else {
        toast.error("Failed to delete records");
      }
    } catch (error) {
      toast.error("An error occurred, try after some time");
    }
  };

  if (
    user?.email !== process.env.REACT_APP_ADMIN_EMAIL_FIRST &&
    user?.email !== process.env.REACT_APP_ADMIN_EMAIL_SECOND
  ) {
    return <>You are not an admin</>;
  }

  return (
      <div style={{display:"flex" ,flexDirection:"column" ,gap:"20px"}}>
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <h1 className={styles.title}>Admin Dashboard</h1>

        <div className={styles.actionButtons}>
          <button onClick={handleDeleteFiles} className={styles.deleteButton}>
            Delete Records
          </button>
          <button onClick={openCreateUserModal} className={styles.createButton}>
            Create New User
          </button>
          <button onClick={openUpdateUserModal} className={styles.updateButton}>
            Update Password
          </button>

        </div>

        {isCreateUserModalOpen && (
            <CreateUserModal onClose={closeCreateUserModal}/>
        )}
        {isUpdateUserModalOpen && (
            <UpdateUserModal onClose={closeUpdateUserModal}/>
        )}
      </div>

      <TableComponent />
    </div>
<ShowUsersModal/>
  </div>
  );
};

export default AdminDashboard;
